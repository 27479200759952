// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { FileNotFoundRoutingModule } from './404.routing'

// Components
import { FileNotFoundComponent } from './404.component'

@NgModule({
  declarations: [FileNotFoundComponent],
  imports: [CommonModule, SharedModule, FileNotFoundRoutingModule],
  exports: [],
  providers: [],
})
export class FileNotFoundModule {}
