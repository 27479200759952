<app-header></app-header>

<div class="layout">
  <div class="layout-left e11-border-r e11-border-skin-app-borders">
    <app-nav-left></app-nav-left>
  </div>
  <div class="layout-content e11-p-4 e11-w-full">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>

<!-- Backdrop in the root of every module -->
<e11-backdrop></e11-backdrop>
