import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { E11SideOverService } from '@engineering11/ui-lib/e11-side-over'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { UiTemplate } from '../modal-container/ui-template'

@Component({
  selector: 'sideover-container',
  templateUrl: './sideover-container.component.html',
  styleUrls: ['./sideover-container.component.scss'],
})
export class SideoverContainerComponent implements OnInit {
  @Input() headerTitle = ''

  @Input() subTitle = ''

  @Input() showDeleteButton: boolean = false
  @Input() showSubmitButton: boolean = true

  @Input() loading: boolean = false
  @Input() submitDisabled: boolean = false // Consider reaching into the form in a standardised way

  @Output() removeItem = new EventEmitter()

  @Output() submitItem = new EventEmitter()

  @ViewChild('sideOverContent') sideOverContent!: TemplateRef<any>

  @ContentChildren(UiTemplate) templates!: QueryList<any>

  @Input()
  closeIconLocation: 'left' | 'right' = 'right'

  uiTemplates: any = {}
  isOpen = false

  constructor(
    private sideOverService: E11SideOverService,
    private scrollLockService: UiScrollLockingService,
    private backdropService: E11BackdropService
  ) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.templates.forEach(item => {
      this.uiTemplates[item.getType()] = item.template
    })
  }

  open() {
    this.isOpen = true
    this.sideOverService.overlayToggle(true, this.sideOverContent, { size: 'lg' })
    this.scrollLockService.scrollLock(true)
    // this.backdropService.backdropDisplay(true, 'dark')
  }

  close() {
    this.sideOverService.overlayToggle(false)
    this.scrollLockService.scrollLock(false)
    // this.backdropService.backdropDisplay(false, '')
    // TODO: How to maintain the animation - setTimeout doesn't work because it allows the user to break their state by closing and quickly reopening
    this.isOpen = false // rip contents out of the DOM on close
  }
}
