// Framework
import { NgModule } from '@angular/core'
import { canActivate, redirectLoggedInTo } from '@angular/fire/compat/auth-guard'
// import { canActivate } from '@angular/fire/compat/auth-guard'
import { Routes, RouterModule } from '@angular/router'
import { redirectLoggedInToHome, redirectLoggedInToHomeGuard, superAuthGuard } from '@engineering11/auth-web'
import { PlatformAdminNotificationsModule, PlatformAdminUserModule } from '@engineering11/platform-admin-web'
import { FormSelectService } from 'shared-lib'
import { MainLayoutComponent } from './components/main/main.component'
// Platform specific
// Guards, redirects,

const PlatformAdminConfig = {
  authTokenFn: () => localStorage.getItem('token') as string,
  user: { b2bRoles: FormSelectService.getAllRolesMultiList(), b2cRoles: [] },
}

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    ...superAuthGuard(),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    ...superAuthGuard(),
  },
  {
    path: 'notifications',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => {
          PlatformAdminUserModule.platformAdminConfig = PlatformAdminConfig
          return PlatformAdminNotificationsModule
        },
      },
    ],
    ...superAuthGuard(),
  },
  {
    path: 'users',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => {
          PlatformAdminUserModule.platformAdminConfig = PlatformAdminConfig
          return PlatformAdminUserModule
        },
      },
    ],
    ...superAuthGuard(),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  // Wild Card Route for 404 - FNF
  // {
  //   path: '**',
  //   loadChildren: () => import('./modules/_404/404.module').then(m => m.FileNotFoundModule),
  // },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 120],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],

  providers: [],
})
export class AppRoutingModule {}
