import { Component, OnInit, ViewChild } from '@angular/core'

import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { SideoverContainerComponent } from '../sideover-container/sideover-container.component'

import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss'],
})
export class NavLeftComponent implements OnInit {
  // Examples for implementation
  @ViewChild('modalTemplate') modalTemplate!: E11ModalComponent
  @ViewChild('sideoverContainer') sideoverContainer!: SideoverContainerComponent
  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent

  subNavOpen: number = 0

  constructor(
    private translate: TranslateService,
    private notificationsService: E11NotificationsService,
    private backdropService: E11BackdropService
  ) {}

  ngOnInit(): void {}

  backdropOpen() {
    // this.backdropService.backdropDisplay(true, 'dark')
  }

  modalOpen() {
    this.modalTemplate.open()
  }

  notify() {
    // Send Notification
    // Get translations for the title and message
    let title = '',
      message = ''
    this.translate.get('Test!').subscribe((res: string) => {
      title = res
    })
    this.translate.get('Nice! Successfully tested.').subscribe((res: string) => {
      message = res
    })
    this.notificationsService.popNotificationMessage(new E11NotificationMessage(title, message, 'success', true, ''))
    // End Notification
  }

  sideOver() {
    this.sideoverContainer.open()
  }

  confirmationOpen() {
    this.confirmation.open()
  }

  confirmationAnswer(event: any) {}

  toggleSubNav(item: number) {
    if (this.subNavOpen === item) {
      this.subNavOpen = 0
    } else {
      this.subNavOpen = item
    }
  }
}
