// Framework
import { Component } from '@angular/core'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'

// Platform specific
import * as fromReduce from '@admin/app/store/reducers'
import { OnLogIn } from 'shared-lib'

// Third party
import jwt_decode from 'jwt-decode'
import { Store } from '@ngrx/store'
import { UserService } from '@engineering11/user-web'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthenticationLoginComponent {
  redirectPath = 'home'
  constructor(public router: Router, private store: Store<fromReduce.user.State>, private userService: UserService) {}

  onError(error: Error): void {
    throw error
  }

  onForgottenPassword() {
    this.router.navigate(['/auth/recover'])
  }

  async onSubmit(token: string): Promise<void> {
    const decoded: any = jwt_decode(token)
    this.store.dispatch(new OnLogIn({ token, appUserId: decoded.appUserId, redirectPath: this.redirectPath }))
  }
}
