import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// E11 Components
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ChartPercentageModule } from '@engineering11/ui-lib/e11-chart-percentage'
import { E11ChipModule } from '@engineering11/ui-lib/e11-chip'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { E11SideOverModule } from '@engineering11/ui-lib/e11-side-over'
import { E11TextEditorModule } from '@engineering11/ui-lib/e11-text-editor'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11LoaderSpinnerModule } from '@engineering11/ui-lib/e11-loader-spinner'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11CardModule } from '@engineering11/ui-lib/e11-card'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11SidesheetModule } from '@engineering11/ui-lib/e11-sidesheet'
import { E11TitleBarModule } from '@engineering11/ui-lib/e11-title-bar'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11LoaderModule } from '@engineering11/ui-lib/e11-loader'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11PhoneNumberModule } from '@engineering11/ui-lib/e11-phone-number'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11ImageModule } from '@engineering11/ui-lib/e11-image'
import { E11ReadMoreModule } from '@engineering11/ui-lib/e11-read-more'
import { E11CountdownModule } from '@engineering11/ui-lib/e11-countdown'
import { E11AlertModule } from '@engineering11/ui-lib/e11-alert'

const ImportExportModules = [
  E11BackdropModule,
  E11BadgeModule,
  E11ButtonModule,
  E11CardModule,
  E11ChartPercentageModule,
  E11ChipModule,
  E11DividerModule,
  E11EmptyStateModule,
  E11LoaderDotsModule,
  E11LoaderSpinnerModule,
  E11LoaderModule,
  E11ProfilePicModule,
  E11RadioInputModule,
  E11InputModule,
  E11TooltipModule,
  E11PanelModule,
  E11TitleBarModule,
  E11PhoneNumberModule,
  E11SideOverModule,
  E11ConfirmationModule,
  E11ImageModule,
  E11ReadMoreModule,
  E11SidesheetModule,
  E11TextEditorModule,
  E11CountdownModule,
  E11AlertModule,
  E11TitleBarModule,
  E11ModalModule,
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class ComponentLibraryModule {}
