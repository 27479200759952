// Framework
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

// Routing
import { AuthenticationRoutingModule } from './authentication.routing'

// Modules
import { SharedModule } from '../_shared/shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { ComponentLibraryModule } from '../_component-library/component-library.module'

// Components
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthModule, IAuthConfig } from '@engineering11/auth-web'
import { UserModule } from '@engineering11/user-web'
//import { AuthenticationCodeWallComponent } from './views/code-wall/code-wall.component'
import { RecaptchaModule } from 'ng-recaptcha'

import { environment } from '../../../environments/environment'

const authConfig: IAuthConfig = {
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: '',
  tenantId: environment.tenantId,
}
@NgModule({
  declarations: [
    AuthenticationComponent,
    AuthenticationLoginComponent,
    //AuthenticationCodeWallComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    AuthModule.forRoot(authConfig),
    UserModule,
    AuthenticationRoutingModule,
    ComponentLibraryModule,
  ],
  exports: [],
  providers: [],
})
export class AuthenticationModule {}
