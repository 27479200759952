import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeComponent } from './home.component'
import { SharedModule } from '../_shared/shared.module'
import { ComponentLibraryModule } from '../_component-library/component-library.module'

// Local module views
import { HomeHomeComponent } from './views/home/home.component'
import { SecondaryComponent } from './views/secondary/secondary.component'

import { HomeRoutingModule } from './home.routing'

@NgModule({
  declarations: [HomeComponent, HomeHomeComponent, SecondaryComponent],
  imports: [CommonModule, SharedModule, ComponentLibraryModule, HomeRoutingModule],
})
export class HomeModule {}
