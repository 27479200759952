<div class="row flex">
  <div class="col-xs-12">
    <div class="wrapper-panel">
      <e11-panel size="sm" borderRadius="lg">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h3 class="bold color-head">
              {{ 'Admin Sign in' | translate }}
            </h3>
            <div class="spacer-2rem"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <auth-login (onSubmit)="onSubmit($event)" (onError)="onError($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>

            <div class="spacer-2rem"></div>
          </div>
        </div>
      </e11-panel>
    </div>
  </div>
</div>

<!-- <responsive-warning></responsive-warning> -->
