<div class="">
  <!-- "nav" is for navigation ONLY -->
  <nav class="e11-p-4">
    <ul>
      <li [routerLink]="['/home']" [routerLinkActive]="['active']">Home</li>
      <li [routerLink]="['/notifications']" [routerLinkActive]="['active']">Notifications</li>
      <li [routerLink]="['/users']" [routerLinkActive]="['active']">Users</li>
      <!-- <li
        class="hover:e11-bg-skin-primary-accent/70 hover:e11-text-skin-white"
        [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-skin-white': subNavOpen === 1 }"
        (click)="toggleSubNav(1)"
      >
        {{ 'Dashboard' | translate }}
        <span class="material-icons-outlined e11-ml-2" *ngIf="subNavOpen !== 1"> expand_more </span>
        <span class="material-icons-outlined e11-ml-2" *ngIf="subNavOpen === 1"> expand_less </span>
      </li>
      <ul class="e11-nav-sub-vertical" [ngClass]="{ active: subNavOpen === 1 }">
        <li
          class="hover:e11-bg-skin-primary-accent/20"
          [routerLink]="['/dashboard']"
          [routerLinkActive]="['active', 'e11-text-skin-primary-accent', 'before:e11-bg-skin-primary-accent']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Home
        </li>
        <li
          class="hover:e11-bg-skin-primary-accent/20"
          [routerLink]="['/dashboard/page-one']"
          [routerLinkActive]="['active', 'e11-text-skin-primary-accent', 'before:e11-bg-skin-primary-accent']"
        >
          Page 1
        </li>
        <li
          class="hover:e11-bg-skin-primary-accent/20"
          [routerLink]="['/dashboard/page-two']"
          [routerLinkActive]="['active', 'e11-text-skin-primary-accent', 'before:e11-bg-skin-primary-accent']"
        >
          Page 2
        </li>
        <li>Page 3</li>
      </ul>
      <li
        class="hover:e11-bg-skin-primary-accent/70 hover:e11-text-skin-white"
        [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-skin-white': subNavOpen === 2 }"
        (click)="toggleSubNav(2)"
      >
        {{ 'Sandbox' | translate }}
        <span class="material-icons-outlined e11-ml-2" *ngIf="subNavOpen !== 2"> expand_more </span>
        <span class="material-icons-outlined e11-ml-2" *ngIf="subNavOpen === 2"> expand_less </span>
      </li>
      <ul class="e11-nav-sub-vertical" [ngClass]="{ active: subNavOpen === 2 }">
        <li
          class="hover:e11-bg-skin-primary-accent/20"
          [routerLink]="['/sandbox']"
          [routerLinkActive]="['active', 'e11-text-skin-primary-accent', 'before:e11-bg-skin-primary-accent']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          Home
        </li>
        <li
          class="hover:e11-bg-skin-primary-accent/20"
          [routerLink]="['/sandbox/page-one']"
          [routerLinkActive]="['active', 'e11-text-skin-primary-accent', 'before:e11-bg-skin-primary-accent']"
        >
          Page 1
        </li>
        <li
          class="hover:e11-bg-skin-primary-accent/20"
          [routerLink]="['/sandbox/page-two']"
          [routerLinkActive]="['active', 'e11-text-skin-primary-accent', 'before:e11-bg-skin-primary-accent']"
        >
          Page 2
        </li>
        <li>Page 3</li>
      </ul>
      <li [routerLink]="['/dashboard/404']">Module 404</li>
      <li [routerLink]="['/mhmdsfhmdf']">App level 404</li> -->
    </ul>
  </nav>

  <!-- <e11-divider [margin]="1" [width]="100"></e11-divider>
  <aside class="e11-p-4">
  </aside> -->

  <!-- <div class="e11-p-4">
    <p>Non navigational stuff</p>
    <ul>
      <li (click)="backdropOpen()">Backdrop</li>
      <li (click)="modalOpen()">Modals</li>
      <li (click)="notify()">Notifications</li>
      <li (click)="sideOver()">SideOver</li>
      <li (click)="confirmationOpen()">Confirmation</li>
    </ul>
  </div> -->
  <!-- Non navigational items go here -->
</div>

<!-- Import modal component, open con click with directive.open()-->
<modal-template #modalTemplate></modal-template>

<sideover-container
  #sideoverContainer
  [headerTitle]="'Generic SideOver' | translate"
  [subTitle]="'Lorem ipsum text....' | translate"
  [showDeleteButton]="true"
  [showSubmitButton]="true"
  (removeItem)="(false)"
  (submitItem)="(false)"
>
  <ng-template uiTemplate="content">
    <div class="e11-p-4">
      <h1>Hi there</h1>
      <h2>Anything</h2>
      <p>Paragraph text...</p>
    </div>
  </ng-template>
</sideover-container>

<e11-confirmation
  #confirmation
  confirmTitle="{{ 'Example Confirmation' | translate }}"
  confirmText="{{ 'Continue' | translate }}"
  cancelText="{{ 'Cancel' | translate }}"
  [backdropStyle]="'dark'"
  (answer)="confirmationAnswer($event)"
>
  {{ 'This is my confirmation message - question...' | translate }}
</e11-confirmation>
