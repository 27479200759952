// Framework
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// import { canActivate } from '@angular/fire/compat/auth-guard'
// import { superGuard } from '@engineering11/auth-web'

// Platform specific
import { HomeComponent } from './home.component'
import { HomeHomeComponent } from './views/home/home.component'
import { SecondaryComponent } from './views/secondary/secondary.component'

// import { FileNotFoundComponent } from '../_404/404.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: HomeHomeComponent,
        pathMatch: 'full',
      },
      {
        path: 'secondary',
        component: SecondaryComponent,
        pathMatch: 'full',
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
