// Framework
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { redirectLoggedInToHomeGuard } from '@engineering11/auth-web'
// This 'DenyGuard' is used to block users from gettign back to the 'auth' module post login
// All 'user' management should be done in a 'users' module. 'auth' is not reusable for user management
import { FileNotFoundComponent } from '../_404/404.component'
// Platform specific
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationLoginComponent } from './views/login/login.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: 'login',
        component: AuthenticationLoginComponent,
        // ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'login/:deepLink',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
