import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'

@Component({
  selector: 'modal-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class ModalTemplateComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: E11ModalComponent
  @Output() closingModal = new EventEmitter()

  constructor(private backdropService: E11BackdropService) {}

  ngOnInit(): void {}

  open() {
    this.modalTemplate.open()
    // this.backdropService.backdropDisplay(true, 'dark')
  }

  close() {
    this.modalClosed
    this.modalTemplate.close()
    this.backdropService.backdropDisplay(false, '')
  }

  modalClosed() {}
}
