import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FooterComponent } from '../../components/footer/footer.component'
import { HeaderComponent } from '../../components/header/header.component'
import { NavLeftComponent } from '../../components/nav-left/nav-left.component'
import { ComponentLibraryModule } from '../_component-library/component-library.module'
import { NavHeaderComponent } from '../../components/nav-header/nav-header.component'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { AppFileNotFoundComponent } from '../../components/_404/file-not-found.component'
import { SideoverContainerComponent } from '../../components/sideover-container/sideover-container.component'
import { SharedLibModule } from 'shared-lib'
import { environment } from 'projects/admin/src/environments/environment'
import { ERROR_HANDLING } from 'projects/admin/src/app/app.constants'

// Modals
import { ModalTemplateComponent } from '../../components/modals/template/template.component'

const DeclareExportComponents = [
  FooterComponent,
  HeaderComponent,
  NavLeftComponent,
  NavHeaderComponent,
  ModalTemplateComponent,
  AppFileNotFoundComponent,
  SideoverContainerComponent,
]

@NgModule({
  declarations: [...DeclareExportComponents],
  imports: [CommonModule, ComponentLibraryModule, RouterModule, TranslateModule, SharedLibModule.forRoot(environment, ERROR_HANDLING)],
  exports: [...DeclareExportComponents, TranslateModule],
})
export class SharedModule {}
