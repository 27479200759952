<div class="e11-w-full e11-flex e11-flex-col e11-items-center e11-justify-center e11-p-16">
  <h1>404 - File Not Found</h1>
  <h3>{{ 'File not found title...' | translate }}</h3>
  <p class="e11-text-light">
    {{ 'File not found message...' | translate }}
  </p>
  <p class="e11-text-light">
    {{ 'This is a problem inside the current modules routing. Authenticated user with probably a system generated link...' | translate }}
  </p>
  <p class="e11-flex e11-items-center e11-cursor-pointer" [routerLink]="['/home']">
    <span class="material-icons-outlined md-36 e11-mr-2"> chair </span>
    {{ 'Relax' | translate }}
  </p>
</div>
