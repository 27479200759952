<header class="e11-bg-skin-app-bg e11-border-b e11-border-skin-app-borders e11-p-4 e11-flex e11-items-center">
  <div class="e11-w-full e11-flex e11-items-center">
    <div class="e11-grow">
      <!-- Dynamic header logo -->
      <img src="../../../assets/images/logos/logo.png" alt="" width="160" class="e11-cursor-pointer" [routerLink]="['/home']" />
    </div>
    <div class="e11-grow e11-flex e11-items-center e11-justify-end">
      <span class="e11-text-skin-dark hover:e11-text-skin-primary e11-cursor-pointer">
        <span class="e11-flex e11-items-center" (click)="signOut()">
          {{ 'Sign Out' | translate }}
          <span class="material-icons-outlined e11-ml-2"> logout </span>
        </span>
      </span>
    </div>
  </div>
</header>
