// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IPlatformAdminEnvironment } from 'shared-lib'

export const environment: IPlatformAdminEnvironment = {
  production: false,
  env: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyCnP-U4Q9cCTJ6jCB5aAfdNdPm66Owq6_4',
    authDomain: 'cnect-dev.firebaseapp.com',
    databaseURL: 'https://cnect-dev.firebaseio.com',
    projectId: 'cnect-dev',
    storageBucket: 'cnect-dev.appspot.com',
    messagingSenderId: '114692117909',
    appId: '1:114692117909:web:74e5f41c97794b8174129c',
    measurementId: 'G-2VQ0MJS397',
  },

  services: {
    jobs: 'https://jobs-rest-dot-cnect-dev.uc.r.appspot.com',
    user: 'https://user-rest-dot-cnect-dev.uc.r.appspot.com',
    auth: 'https://auth-rest-dot-cnect-dev.uc.r.appspot.com/',
    registration: 'https://registration-rest-dot-cnect-dev.uc.r.appspot.com',
    files: 'https://files-api-gateway-6p15ahzk.uc.gateway.dev',
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6Lfrcs4aAAAAAJBFYy_tXUxRQw-P1z-0o9r57-xN',
  tenantId: 'PLATFORM-USER-n81ik',
  algoliaAppId: 'SH1DAV8W6D',
  cdnCname: 'dev-content.cnected.com',
  version: '0e64d29',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
