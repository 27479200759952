import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/compat/auth'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { AuthModule, IAuthConfig } from '@engineering11/auth-web'
import { FilesModule } from '@engineering11/files-web'
import { IRegistrationConfig, RegistrationModule } from '@engineering11/registration-web'
import { E11NotificationsModule } from '@engineering11/ui-lib/e11-notifications'
import { UiErrorsModule } from '@engineering11/ui/ui-errors'
import { UiInputErrorsModule } from '@engineering11/ui/ui-input-errors'
import { UiNotificationsModule } from '@engineering11/ui/ui-notifications'
import { UiToastModule } from '@engineering11/ui/ui-toast'
import { UserModule } from '@engineering11/user-web'
import { E11ErrorModule, GlobalErrorHandler } from '@engineering11/web-api-error'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NgAisModule } from 'angular-instantsearch'
import firebase from 'firebase/compat/app'
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { BrowserDetectionService } from 'projects/shared-lib/src/lib/service/browser-detection.service'
import { componentStateReducer, errorHandlerOverrides, WebpackTranslateLoader } from 'shared-lib'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MainLayoutComponent } from './components/main/main.component'
import { CnectFilesConfigProvider } from './config/files.config'
import { ServerErrorInterceptor } from './error-server.interceptor'
import { AuthenticationModule } from './modules/authentication/authentication.module'
import { HomeModule } from './modules/home/home.module'
import { FileNotFoundModule } from './modules/_404/404.module'
import { ComponentLibraryModule } from './modules/_component-library/component-library.module'
import { SharedModule } from './modules/_shared/shared.module'
import { rollbarFactory } from './rollbar'
import { ConfigService } from './services/config.service'
import * as fromStore from './store/index'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig()
}

firebase.initializeApp(environment.firebaseConfig)
try {
  firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true })
} catch (e) {}

const registrationConfig: IRegistrationConfig = {
  registrationBaseURL: environment.services.registration,
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: '',
  tenantId: environment.tenantId,
}

const authConfig: IAuthConfig = {
  authBaseURL: environment.services.auth,
  emailVerificationEmailTemplateId: '',
  tenantId: environment.tenantId,
}

@NgModule({
  declarations: [AppComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        // useFactory: HttpLoaderFactory,
        // deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    CloudflareStreamModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    RecaptchaModule,
    ComponentLibraryModule,
    SharedModule,
    AuthenticationModule,
    HomeModule,
    FileNotFoundModule,
    UiErrorsModule.forRoot(),
    UiNotificationsModule.forRoot(),
    E11NotificationsModule.forRoot(),
    UiToastModule.forRoot(),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgAisModule.forRoot(),
    HttpClientModule,
    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF, serverLogLevel: NgxLoggerLevel.OFF }),
    StoreModule.forRoot({ ...fromStore.reducers, componentStore: componentStateReducer }),
    EffectsModule.forRoot(fromStore.effects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    UiInputErrorsModule.forRoot({}),
    FilesModule.forRoot({ configProvider: CnectFilesConfigProvider }),
    E11ErrorModule.forRoot({ errorTrackerFactory: rollbarFactory, logger: NGXLogger, errorHandlerMap: errorHandlerOverrides }),
    AuthModule.forRoot(authConfig),
    RegistrationModule.forRoot(registrationConfig),
    UserModule.forRoot({ userBaseURL: environment.services.user }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: TENANT_ID, useValue: environment.tenantId },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.v2ReCaptcha } as RecaptchaSettings,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    BrowserDetectionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
