import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-file-not-found',
  templateUrl: './file-not-found.component.html',
  styleUrls: ['./file-not-found.component.scss'],
})
export class AppFileNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
