<e11-modal
  #modalTemplate
  [width]="'lg'"
  title="{{ 'Modal Template' | translate }}"
  [showHeader]="true"
  [hideCloseButton]="false"
  [headerClassOverrides]="'e11-p-4'"
  (modalClosed)="modalClosed()"
>
  <div modal-body class="e11-text-center">
    <div class="e11-w-full e11-py-8">
      <h3 class="e11-text-skin-primary-accent">{{ 'You are looking at a test modal.' | translate }}</h3>
      <p>{{ 'What part of that do you not understand?.' | translate }}</p>
      <p>{{ 'Use this to make more modals.' | translate }}</p>
    </div>

    <div class="e11-w-full e11-text-center">
      <e11-button [style]="'pill outlined'" value="{{ 'Close' | translate }}" [color]="'neutral'" (click)="close()"> </e11-button>
    </div>
  </div>
</e11-modal>
