<app-header></app-header>

<div class="layout">
  <!-- <div class="layout-left e11-border-r e11-border-skin-app-borders">
    <app-nav-left></app-nav-left>
  </div> -->

  <div class="e11-w-full e11-flex e11-flex-col e11-items-center e11-justify-center e11-p-16">
    <h1>404 - Application Level</h1>
    <h3>{{ 'File not found title...' | translate }}</h3>
    <p class="e11-text-light">
      {{ 'File not found message...' | translate }}
    </p>
    <p class="e11-text-light">
      {{ 'This is a larger scale issue, application level, maybe an unauthorized user with a completely non-existent link...' | translate }}
    </p>
    <p class="e11-flex e11-items-center e11-cursor-pointer" [routerLink]="['/home']">
      <span class="material-icons-outlined md-36 e11-mr-2"> chair </span>
      {{ 'Relax' | translate }}
    </p>
  </div>
</div>

<app-footer></app-footer>
